import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const containerVariants = {
  hidden: {
    opacity: 0,
    translateY: 15,
  },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.5,
    },
  },
}

const AboutSection = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.4,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      ref={ref}
      className="border-b"
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="container mx-auto max-w-6xl px-6 py-28 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
            In the digital age
            <br />
            Your brand is always in beta.
          </h1>
          <ul className="mt-6 space-y-2 text-gray-400">
            <li>Award Winning Agency</li>
            <li>Aesthetics Oriented Team</li>
            <li>Marketing Strategy Experts</li>
          </ul>
        </div>
        <div className="space-y-6">
          <p className="leading-relaxed text-justify">
            It’s a constant work in progress. Enim ad minim veniam, quis nost
            exercitation ullamco laboris nisi ut aliquip ex ea commodo conse
            occaecat cupidatat non proident, sunt in culpa qui officia.
          </p>
          <p className="leading-relaxed text-justify">
            Rather than trying to get things perfect the first time, similique
            sut in culpa qui officia deserunt mollitia animi, id est laborum et
            dolor fuga. Et harum quidem rerum facilis est et expedita distinctio
            cum soluta nobis est eligendi optio cumque nihil impedit quo minus
            id.
          </p>
        </div>
      </div>
    </motion.section>
  )
}

export default AboutSection
