import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const containerVariants = {
  hidden: {
    opacity: 0,
    translateY: 15,
  },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
}

const prorjectItemVariants = {
  hidden: {
    opacity: 0,
    translateX: -15,
  },
  visible: {
    opacity: 1,
    translateX: 0,
    transition: {
      duration: 0.4,
    },
  },
}

const PortfolioSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProject {
        nodes {
          id
          name
          slug
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const projects = data?.allContentfulProject?.nodes ?? []
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="container mx-auto max-w-6xl px-6 py-28">
        <div>
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
            Our selected work.
            <br />
            Always a work in progress.
          </h1>
        </div>
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {projects.map(({ id, name, slug, image }) => (
            <motion.div
              key={id}
              className="group"
              variants={prorjectItemVariants}
            >
              <Link to={`/projects/${slug}`}>
                <div className="overflow-hidden">
                  <GatsbyImage
                    image={getImage(image)}
                    className="transition-transform duration-500 ease-in-out transform group-hover:scale-125"
                    alt={name}
                  />
                </div>
                <h3 className="mt-4 group-hover:text-gray-400 transition duration-500 ease-in-out">
                  {name}
                </h3>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  )
}

export default PortfolioSection
