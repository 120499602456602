import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import HeroSection from "../components/HomePage/HeroSection"
import AboutSection from "../components/HomePage/AboutSection"
import PortfolioSection from "../components/HomePage/PortfolioSection"
import CallToActionSection from "../components/CallToActionSection"

const HomePage = () => {
  return (
    <Layout>
      <Seo title="Anything Agency" />
      <HeroSection />
      <AboutSection />
      <PortfolioSection />
      <CallToActionSection />
    </Layout>
  )
}

export default HomePage
