import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"
import { motion, useAnimation } from "framer-motion"

const heroContentVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.7,
      when: "beforeChildren",
      staggerChildren: 0.2,
      ease: "easeOut",
    },
  },
}

const heroContentChildrenVariants = {
  hidden: {
    opacity: 0,
    translateY: 15,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  },
}

const heroImageOverlayVariants = {
  hidden: { width: "100%" },
  visible: {
    width: 0,
    transition: {
      delay: 1.8,
      duration: 0.4,
      ease: "easeInOut",
    },
  },
}

const heroImageVariants = {
  hidden: { scale: 1.2 },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
}

const HeroSection = () => {
  const heroImageAnimationControls = useAnimation()

  return (
    <>
      <section className="min-h-[60vh] flex items-center">
        <motion.div
          className="container mx-auto max-w-6xl px-6 py-10"
          initial="hidden"
          animate="visible"
          variants={heroContentVariants}
        >
          <motion.h1
            variants={heroContentChildrenVariants}
            className="font-medium text-5xl leading-normal tracking-tighter"
          >
            We are Forist.
            <br />
            Award winning digital agency.
          </motion.h1>
          <motion.p
            variants={heroContentChildrenVariants}
            className="mt-5 leading-loose"
          >
            Hello there! We are Forist, a ipsum dolor sit amet, consectetur
            adipiscing
            <br />
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua.
          </motion.p>

          <motion.button
            variants={heroContentChildrenVariants}
            className="mt-8 py-4 px-6 border-2 flex justify-center items-center space-x-2 hover:bg-blue-50 transition-colors duration-100 ease-in-out"
            onClick={() => navigate("/about")}
          >
            <span>Learn more</span>
            <ChevronDoubleRightIcon className="w-4 h-4 text-gray-400" />
          </motion.button>
        </motion.div>
      </section>
      <motion.section className="relative">
        <motion.div
          className="absolute w-full h-full bg-white z-[2] top-0 right-0"
          initial="hidden"
          animate="visible"
          variants={heroImageOverlayVariants}
          onAnimationComplete={() =>
            heroImageAnimationControls.start("visible")
          }
        />
        <div className="overflow-hidden">
          <motion.div
            initial="hidden"
            animate={heroImageAnimationControls}
            variants={heroImageVariants}
          >
            <StaticImage
              src="../../assets/images/home-cta.jpg"
              placeholder="blurred"
              layout="fullWidth"
              alt="home cta image"
            />
          </motion.div>
        </div>
      </motion.section>
    </>
  )
}
export default HeroSection
